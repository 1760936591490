import { ChainId } from '@pancakeswap/sdk'
import { Address } from 'viem'

// ADD CHAIN
export const MULTICALL_ADDRESS: { [key in ChainId]?: Address } = {
  [ChainId.ZETA]: '0x039e87AB90205F9d87c5b40d4B28e2Be45dA4a20',

  // Testnets
  [ChainId.ZETA_TESTNET]: '0x48d7ac38530697aDB91061B6D141C8c763edE565',
  [ChainId.ZULU_TESTNET]: '0x3cB5FA08F9b27Ea9867b97d966B6507B07c467D4',
  [ChainId.SHAPE_TESTNET]: '0x874D98E8c6B172A4944554ECa42194ad09836919',
  [ChainId.MORPH_TESTNET]: '0xd0034aEe8e1d8d8919A401c546Ed2d0F2A4C90F1',
  [ChainId.DUCK_TESTNET]: '0x65fdC9Bd3B38B453b04CEF6425a5d9F5bA5EccC2',
  [ChainId.STORY_TESTNET]: '0x65fdC9Bd3B38B453b04CEF6425a5d9F5bA5EccC2',
}

export const MULTICALL3_ADDRESS = '0xcA11bde05977b3631167028862bE2a173976CA11'

// ADD CHAIN
export const MULTICALL3_ADDRESSES: {
  [key in ChainId]?: Address
} = {
  [ChainId.ZETA]: MULTICALL3_ADDRESS,
  [ChainId.ZETA_TESTNET]: MULTICALL3_ADDRESS,
  [ChainId.ZULU_TESTNET]: '0x3cB5FA08F9b27Ea9867b97d966B6507B07c467D4',
  [ChainId.SHAPE_TESTNET]: '0x65fdC9Bd3B38B453b04CEF6425a5d9F5bA5EccC2',
  [ChainId.MORPH_TESTNET]: '0x874D98E8c6B172A4944554ECa42194ad09836919',
  [ChainId.DUCK_TESTNET]: '0xCd3D31068bD3E2Bac063CD2cEfAFb4b0944b7947',
  [ChainId.STORY_TESTNET]: '0x874D98E8c6B172A4944554ECa42194ad09836919',
}
